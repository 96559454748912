// Import the mixins
// @import "style/mixins/css3-mixins";
// Import other
@import "style/font-face";
@import "style/base";
@import "style/gutter";

// Extra small devices (portrait phones, less than 576px)
$smartphone-small: "(max-width: 575px)";
// Small devices (landscape phones, 576px and up)
$smartphone: "(min-width: 576px) and (max-width: 767px) and (orientation : landscape)";
// Medium devices (tablets, 768px and up)
$tablet: "(min-width: 768px) and (max-width: 991px)";
// Large devices (desktops, 992px and up)
$desktop: "(min-width: 992px) and (max-width: 1199px)";
// Extra large devices (large desktops, 1200px and up)
$large: "(min-width: 1200px)";

$iphone5: "only screen and (max-width : 320px)";

@media #{$iphone5} {
	#services {
		.box-item {
			.title {
				font-size: 16px;
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media #{$smartphone-small} {
	.navbar-main {
		min-height: 80px;
		.navbar-toggle {
			margin-right: 15px;
		    margin-top: 42px;
		    padding: 0;
		    border: 0;
		    .icon-bar {
		    	background-color: $green;
		    }
		}
		.navbar-brand {
		    padding: 15px 15px;
			img {
				width: 150px;
			}
		}
	}
	#home-slider {
		.owl-dots .owl-dot span {
			width: 16px;
		    height: 16px;
		}
		.bg-img {
			height: 300px;
		}
		.carousel-caption {
			// top: 210px;
			top: 90px;
			h1 {
				font-size: 24px;
				span {
					font-size: 24px;
				}
			}
		}
	}
	#page-slider .bg-img {
		height: 242px;
	}
	.btn-pink {
		font-size: 14px;
	    padding: 8px 16px;
	    width: auto;
	}
	#services {
		.box-item {
			margin-bottom: 18px;
			min-height: 226px;
		}
	}
	#compare-slider {
	    padding: 0 42px;
	}
	.compare-box {
		.twentytwenty-before-label,
	    .twentytwenty-after-label {
	    	&:before {
	    		top: 235px;
	    	}
	    }
	}
	#ig-feed {
		padding-bottom: 20px;
		.img-wrapper {
			margin-bottom: 20px;
		}
	}
	#video-slider {
		padding: 0 42px;
		.item-video {
			height: 200px;
		}
	}
	.page-title {
	    font-size: 40px;
	}
	.sidebar {
		margin-bottom: 30px;
	}
	.content {
		.compare {
			margin-bottom: 0;
			.item {
				margin-bottom: 40px;
			}
		}
	}
	#testimonials-slider {
		padding: 0 44px;
	}
	.contact-form {
		.btn {
			width: 100%;
		}
	}
	.maps {
		height: 300px;
	}
	.footer-top {
		padding-bottom: 42px;
	}
	.offcanvas.in {
		top: 88px;
	}

	#services .box-item .title {
		font-size: 14px;
	}

	.heading {
		font-size: 20px;
	}
	#page-heading {
		height: 280px;
	}
	.page-title {
		margin-top: 150px;
		font-size: 34px;
	}
}

// Small devices (landscape phones, 576px and up) and (orientation : landscape)
@media #{$smartphone} {
	.col-xs-20, .col-sm-20, .col-md-20, .col-lg-20 {
		width: 20%;
	    float: left;
	}
	.btn-pink {
		font-size: 14px;
	    padding: 8px 16px;
	    width: auto;
	}
	.col-sm-6 {
		width: 50%;
		float: left;
	}
	.navbar-main {
		min-height: 110px;
		.navbar-toggle {
			margin-right: 15px;
		    margin-top: 42px;
		    padding: 0;
		    border: 0;
		    .icon-bar {
		    	background-color: $green;
		    }
		}
		.navbar-brand {
		    padding: 15px 15px;
			img {
				width: 200px;
			}
		}
	}
	#home-slider {
		.owl-dots .owl-dot span {
			width: 16px;
		    height: 16px;
		}
		.bg-img {
			height: 300px;
		}
		.carousel-caption {
			// top: 210px;
			top: 90px;
			h1 {
				font-size: 32px;
				span {
					font-size: 32px;
				}
			}
		}
	}
	#page-slider .bg-img {
		height: 242px;
	}
	#services .box-item .title {
	    margin-bottom: 18px;
	}
	#compare-slider {
		padding: 0 50px;
	}
	#ig-feed .bg-img {
		height: 103px;
	}
	.sidebar {
	    margin-bottom: 30px;
	}
	.contact-form .btn {
	    width: 100%;
	}
}

// Medium devices (tablets, 768px and up)
@media #{$tablet} {
	.navbar-main {
		.nav-menu {
			float: left;
		    margin-top: 103px;
			&> li {
				&:first-child {
					&> a {
						padding-left: 0;
					}
				}
			}
			&.lang {
				float: right;
			}
		}
	}
	#home-slider {
		.bg-img {
			height: 600px;
		}
		.carousel-caption {
			h1 {
				font-size: 45px;
				span {
					font-size: 45px;
				}
			}
		}
	}
	#page-slider .bg-img {
		height: 300px;
	}
	#compare-slider {
		padding: 0 55px;
	}
	.compare-box {
		.twentytwenty-before-label,
	    .twentytwenty-after-label {
	    	&:before {
	    		top: 235px;
	    	}
	    }
	}
	#ig-feed {
	    .bg-img {
	        height: 120px;
	    }
	}
	.contact-form {
		.form-group {
			.btn {
				float: none;
			}
		}
	}
	.content {
		.compare-box .twentytwenty-before-label:before,
		.compare-box .twentytwenty-after-label:before {
			    top: 184px;
		}
	}
	#testimonials-slider {
		padding: 0 50px;
	}
}

// Large devices (desktops, 992px and up)
@media #{$desktop} {
	.navbar-main {
		.nav-menu {
			float: left;
		    margin-top: 103px;
			&> li {
				&:first-child {
					&> a {
						padding-left: 0;
					}
				}
			}
			&.lang {
				float: right;
			}
		}
	}
	#testimonials-slider {
		padding: 0 50px;
	}
}

// Extra large devices (large desktops, 1200px and up)
@media #{$large} {
}
